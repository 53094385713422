/* ########################  MODIFICHE STILE BOOTSTRAP ###############################  */

@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
.modal-modifica-align {
    min-width: 150px;
}

.alert-danger {
    color: white;
    background-color: red;
    border-color: #ebccd1;
    animation: blinkingText 2s infinite;
}

/* html, body, p, h1, h2, h3, input{
    text-transform: uppercase !important;
} */

canvas {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
}


@keyframes blinkingText {
    0% {
        color: white;
    }
    10% {
        color: white;
    }
    20% {
        color: white;
    }
    30% {
        color: white;
    }
    40% {
        color: white;
    }
    49% {
        color: transparent;
    }
    50% {
        color: transparent;
    }
    99% {
        color: transparent;
    }
    100% {
        color: white;
    }
}

.mobile-insert-data {
    width: 50% !important;
}

.modal-body {
    color: black;
}

.modal-title {
    color: black;
}

.modal-footer {
    color: black;
    border-top: 0 !important;
}

.panel-default>.panel-heading {
    background-color: #09abff;
    color: black;
    font-weight: bold
}

/* ######################## FINE MODIFICHE STILE BOOTSTRAP ###############################  */

.suggerimento {}

.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #ff0000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 10%;
    bottom: 100%;
}

.suggerimento:hover .tooltiptext {
    visibility: visible;
}

h3 {
    font-size: 1.65vw;
}

body {
    background: #0E0B2B !important;
    color: white !important;
    overflow-x: hidden;
}

.btn-header {
    margin-left: 1px;
    margin-top: 1px;
    min-width: 138px;
}

.col {
    text-align: center;
}

.header {
    position: fixed;
    top: -40px;
    left: 0;
    width: 100%;
    height: 188px;
    z-index: 12;
    background: #0e0b2b;
}

#header a.current {
    background: lightskyblue !important;
    color: white !important;
}

.btn-default.active {
    background: #337ab7 !important;
    color: white !important;
}

.erroreForm {
    margin-top: 1px;
    margin-bottom: 2px;
}

.center-div {
    position: absolute;
    margin: auto;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
}

.label-searchbar {
    font-size: 1.0vw;
}

/* ######################## TIME PICKER BOOTSTRAP ###############################  */

ngb-datepicker-navigation-select>.custom-select {
    font-size: 1.2rem !important;
}

.datepicker-custom {
    color: black
}

.datepicker-custom .bg-primary {
    color: #ff0e0e !important;
}

.ngb-tp .form-control {
    font-weight: bold !important;
    min-width: 50px;
    margin-left: 5px;
    margin-right: 5px;
}

.ngb-tp .ngb-tp-spacer {
    margin-left: 5px;
    margin-right: 5px;
}

/* .ngb-dp-arrow {
    visibility: hidden;
} */

/* ######################## FINE TIME PICKER BOOTSTRAP ###############################  */

/* ######################## GESTIONE RISTORANTE ###############################  */

.secondo-elemento-lista {
    background-color: #f1eded;
}

/* ######################## FINE GESTIONE RISTORANTE ###############################  */

/* ######################## GESTIONE MAGAZZINO ###############################  */

.text-item-magazzino {
    font-size: 18px;
    /* font-size: 1.2vw;*/
    margin-top: 0;
    margin-bottom: 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

/* ######################## FINE GESTIONE MAGAZZINO ###############################  */

/* ######################## PAGINA GESTIONE OMBRELLONI   ###############################  */

.st0:hover {
    stroke: white;
    stroke-width: 3;
}

.st2:hover {
    stroke: white;
    stroke-width: 3;
}

.st1:hover {
    stroke: white;
    stroke-width: 3;
}

.st0 {
    fill: #20ab20;
    stroke: #000000;
    stroke-miterlimit: 10;
}

.st2 {
    fill: red;
    stroke: #000000;
    stroke-miterlimit: 10;
}

.st1 {
    fill: #1f91f3;
    stroke: #000000;
    stroke-miterlimit: 10;
    /* pointer-events: none; */
}

.foto-profilo {
    max-width: 110px !important;
}

.testo-ombr {
    text-decoration: none !important;
    font-size: 1.3em;
    fill: white;
}

.testo-ombr:hover {
    text-decoration: none !important;
}

svg a:hover {
    text-decoration: none !important;
}

/* ######################## FINE PAGINA GESTIONE OMBRELLONI   ###############################  */

/* ######################## PAGINA GESTIONE ORDINI BAR   ###############################  */

.allinea-sx {
    float: left !important;
}

.allinea-dx {
    text-align: right;
}

.ordine-disabilitato-on {
    pointer-events: none;
}

.ordine-disabilitato-off {
    pointer-events: all;
}

/* ######################## FINE PAGINA GESTIONE ORDINI BAR   ###############################  */

@media screen and (min-width: 320px) and (max-width: 767px) {
    .foto-profilo {
        max-width: 55px !important;
    }
    .btn-header {
        margin-top: 2px;
        min-width: 95% !important;
    }
    .btn.btn-info.btn-md {
        width: 100%;
    }
    #login-column {
        width: 100%;
    }
    .mobile-insert-data {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .alert-danger {
        font-size: 1.8vh !important;
    }
}

@media screen and (min-width: 1366px) and (max-width: 1919px) {
    .foto-profilo {
        max-width: 65px !important;
    }
}

.bg-primary {
    color: #ff0e0e !important;
}

.ngb-dp-navigation-select {
    color: black !important;
}

.ngx-pagination {
    text-align: center !important;
}

#gestione-ordini-bar .ngx-pagination .disabled {
    color: grey !important;
}

#gestione-ordini-bar .ngx-pagination a, .ngx-pagination button {
    color: white;
}

.ngb-dp-day.hidden {
    display: block !important;
    visibility: hidden !important;
}

/* Modifiche 2020-2021 */

a.btn.btn-header.btn-default, .btn-info, .btn-primary, .btn-danger, .btn-secondary {
    text-decoration: none;
    color: #1db6f0;
    background: transparent;
    border: 2px solid #1db6f0;
    transition: none;
    margin: 0 auto;
    border-radius: 0;
    text-transform: uppercase;
    min-width: 225px;
    margin-bottom: 8px;
    margin-right: 8px;
}

a.btn.btn-primary.btn-datepicker {
    margin: 0 auto;
    min-width: 225px;
}

a.btn.btn-header.btn-default:hover, a.btn.btn-primary.btn-datepicker:hover, .btn-datepicker-selector.btn-info:hover, .btn-primary:hover {
    background-color: transparent;
    color: #7982ff;
    border: 2px solid #7982ff;
    /*     color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; */
}

.form-group.input-datepicker input {
    height: 36px;
}

.btn-datepicker-selector {
    margin: 0;
    margin-right: 8px;
}

.form-control {
    border-radius: 0;
}

.panel {
    border-radius: 0;
}

.btn-danger {
    background: red;
    color: white;
    border: 2px solid #d43f3a;
}

button.btn-modal {
    width: 120px;
    min-width: 50px;
    margin: 0;
}

button.btn.btn-warning.btn-info {
    background: #fea501;
    color: #fff;
    border-color: white;
}

button.btn.btn-warning.btn-info:hover {
    border-color: #1db6f0;
}

button.btn.btn-danger.btn-modal.btn-modal-delete {
    width: 100%;
    color: white;
    margin-bottom: 8px;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
}

.ordinePagatoOn {
    background-color: green !important;
    color: #fff !important;
}

.ordinePagatoOff {
    /*   background-color: #d4d4d4 !important;
    border-color: #d4d4d4 !important;
    color: black !important; */
}

.ordineNonPagatoOn {
    background-color: #ac2925 !important;
    border-color: #761c19 !important;
    color: #fff !important;
}

.ordineNonPagatoOff {
    /*  background-color: #d4d4d4 !important;
    color: black !important;
    border-color: #d4d4d4 !important; */
}

button.btn.btn-success.btn-info {
    background: green;
    color: white;
    border-color: white;
}

.row.well.well-sm:hover {
    background-color: transparent;
    color: #7982ff;
    border: 2px solid #7982ff;
}

.panel-body {
 /*    color: #37b6f0 !important; */
    color: white;
    background: #0f0b2b;
    border: none;
    font-size: 1.8rem;
}

.well {
    background-color: transparent;
    border: none;
    margin-bottom: 0;
    color: white;
    font-size: 1.8rem;

}

.well-sm {
    padding: 4px;
    border-radius: 0;
    margin: 4px;
}

.panel {
    background-color: transparent;
}

.ngx-pagination a, .ngx-pagination button {
    color: #31abff !important;
}

.modal-content {
    border-radius: 0;
}

span.far.fa-trash-alt:hover, .fa-print:hover  {
    color: red !important;
}

/* IPAD */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .main__logo__container {
        text-align: center;
        float: unset;
        margin: 0 auto;
    }
    a.btn.btn-header.btn-default, .btn-info, .btn-primary, .btn-danger, .btn-secondary {
        min-width: 155px;
    }
    a.btn.btn-primary.btn-datepicker {
        min-width: 155px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .main__logo {
        width: 180px;
    }
    a.btn.btn-header.btn-default, .btn-info, .btn-primary, .btn-danger, .btn-secondary {
        min-width: 180px !important;
    }
    a.btn.btn-primary.btn-datepicker {
        min-width: 180px !important;
    }
}

@media only screen and (max-device-width: 767px) {
    a.btn.btn-primary.btn-datepicker, .btn-primary {
        width: 100%;
    }
    .form-group.input-datepicker input {
        margin-bottom: 8px;
    }
    .list-group {
        padding: 0;
    }
    .button__container {
        margin: 30px 15px;
    }
    .button__container button.btn.btn-primary {
        width: 100% !important;
    }
}

.modal-content {
    border: 4px solid #1db6f0;
}